@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
    font-family: Pelak-Web;
    font-style: normal;
    font-weight: 200;
    font-display: swap;
    src:
        url("../fonts/woff2/Pelak-Regular.woff2") format("woff2"),
        url("../fonts/woff/Pelak-Regular.woff") format("woff"),

}

@font-face {
    font-family: Pelak-Web;
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src:
        url("../fonts/woff2/Pelak-Medium.woff2") format("woff2"),
        url("../fonts/woff/Pelak-Medium.woff") format("woff"),
}

@font-face {
    font-family: Pelak-Web;
    font-style: normal;
    font-weight: 800;
    font-display: swap;
    src:
        url("../fonts/woff2/Pelak-ExtraBold.woff2") format("woff2"),
        url("../fonts/woff/Pelak-ExtraBold.woff") format("woff"),
}

html {
    scroll-behavior: smooth;
}

.text-justify {
    text-align: justify;
}

select {
    background-image: url(data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 fill=%27none%27 viewBox=%270 0 20 20%27%3e%3cpath stroke=%27%236B7280%27 stroke-linecap=%27round%27 stroke-linejoin=%27round%27 stroke-width=%271.5%27 d=%27M6 8l4 4 4-4%27/%3e%3c/svg%3e);
    background-position: left 0.5rem center;
}

.text-right {
    text-align: right !important;
}

.line-h-35 {
    line-height: 35px !important;
}

.divide-x-2> :not([hidden])~ :not([hidden]) {
    --tw-divide-x-reverse: 0;
    border-left-width: calc(2px * var(--tw-divide-x-reverse));
    border-right-width: calc(2px * calc(1 - var(--tw-divide-x-reverse)));
}

.hover\:bg-yellow-50:hover {
    --tw-bg-opacity: 0.5 !important;
}

.text-2xs {
    font-size: 10px;
    line-height: normal;
}

.text-nowrap {
    text-wrap: nowrap;
}

/* width */
::-webkit-scrollbar {
    width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
    background: #e5e7eb;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: #a2a8b3;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: #b9bfca;
}

.outer-wrapper {
    max-width: 100vw;
    overflow-x: scroll;
    position: relative;
    scrollbar-width: thin;
    -ms-overflow-style: none;
}

.fleximportant {
    display: flex !important;
}

.divide-x> :not([hidden])~ :not([hidden]) {
    --tw-divide-x-reverse: 0;
    border-left-width: calc(1px * var(--tw-divide-x-reverse));
    border-right-width: calc(1px * calc(1 - var(--tw-divide-x-reverse)));
}

@media (any-hover: none) {
    .pseduo-track {
        display: none;
    }
}

.outer-wrapper::-webkit-scrollbar {
    height: 5px;
}

.outer-wrapper::-webkit-scrollbar-track {
    -webkit-box-shadow: 0 !important;
}

.outer-wrapper::-webkit-scrollbar-thumb {
    height: 5px;
    background-color: #a2a8b3 !important;

}

.outer-wrapper::-webkit-scrollbar-thumb:hover {
    background: #b9bfca !important;
}

.outer-wrapper::-webkit-scrollbar:vertical {
    display: none;
}

.inner-wrapper {
    display: flex;
    padding-bottom: 10px;
}

.pseudo-item {
    height: 300px;
    width: 369px;
    margin-right: 59px;
    flex-shrink: 0;
    background-color: #a2a8b3;
}

.pseudo-item:nth-of-type(2n) {
    background-color: #a2a8b3;
}

@media (min-width: 1024px) {
    .lg\:space-x-8> :not([hidden])~ :not([hidden]) {
        --tw-space-x-reverse: 0;
        margin-left: calc(2rem * var(--tw-space-x-reverse)) !important;
        margin-right: calc(2rem * calc(1 - var(--tw-space-x-reverse))) !important;
    }
}

/* Use the imported fonts */
body {
    font-family: 'Pelak-Web', "Inter", "ui-sans-serif", "system-ui",
        "-apple-system", "system-ui";
    /* Fallback to Arial if Pelak-Llight fails to load */
    /* You can define fallback fonts here */
}

/* chart styles */
.apexcharts-tooltip {
    @apply bg-white dark:bg-gray-700 text-gray-500 dark:text-gray-400 border-0 rounded-lg shadow-lg !important;
}

.apexcharts-tooltip .apexcharts-tooltip-title {
    @apply py-2 px-4 bg-gray-100 dark:bg-gray-600 border-b border-gray-200 dark:border-gray-500 !important;
}

.apexcharts-xaxistooltip {
    @apply text-gray-500 border-0 bg-white dark:bg-gray-700 dark:text-gray-300 rounded-lg shadow-lg !important;
}

.apexcharts-tooltip .apexcharts-tooltip-text-y-value {
    @apply dark:text-white;
}

.apexcharts-xaxistooltip-text {
    @apply font-medium text-sm !important;
}

.apexcharts-xaxistooltip:before,
.apexcharts-xaxistooltip:after {
    @apply border-0 !important;
}

/* SVG map styles */
.svgMap-map-wrapper {
    @apply bg-white !important;
}

.svgMap-map-image {
    @apply dark:bg-gray-800;
}

.svgMap-map-controls-wrapper {
    @apply shadow-none left-0 bottom-0 dark:bg-gray-800 !important;
}

.svgMap-map-controls-zoom {
    @apply dark:bg-gray-800 !important;
}

.svgMap-map-wrapper .svgMap-control-button {
    @apply rounded-lg border-solid border border-gray-300 hover:bg-gray-100 dark:border-gray-600 dark:hover:bg-gray-600 !important;
}

.svgMap-map-wrapper .svgMap-control-button.svgMap-zoom-button:after,
.svgMap-map-wrapper .svgMap-control-button.svgMap-zoom-button:before {
    @apply dark:bg-gray-600 dark:hover:bg-gray-500;
}

.svgMap-map-wrapper .svgMap-control-button:first-child {
    @apply mr-2 !important;
}

.svgMap-tooltip {
    @apply bg-white dark:bg-gray-700 shadow-lg rounded-lg border-0 text-left !important;
}

.svgMap-tooltip .svgMap-tooltip-content-container .svgMap-tooltip-flag-container {
    @apply inline-block mr-2 text-left border-0 p-0 !important;
}

.svgMap-tooltip .svgMap-tooltip-content-container .svgMap-tooltip-flag-container .svgMap-tooltip-flag {
    @apply inline-block border-0 h-4 p-0 !important;
}

.svgMap-tooltip .svgMap-tooltip-title {
    @apply inline-block pt-2 text-gray-900 dark:text-white font-semibold text-sm !important;
}

.svgMap-tooltip .svgMap-tooltip-content {
    @apply mt-0 !important;
}

.svgMap-tooltip .svgMap-tooltip-content table td {
    @apply text-sm text-left text-gray-500 dark:text-gray-400 font-normal !important;
}

.svgMap-tooltip .svgMap-tooltip-content table td span {
    @apply text-sm text-left text-gray-900 dark:text-white font-semibold !important;
}

.svgMap-tooltip .svgMap-tooltip-pointer {
    @apply hidden !important;
}

.svgMap-map-wrapper .svgMap-country {
    @apply dark:stroke-gray-800;
}

/* kanban styles */

.drag-card {
    @apply opacity-100 !important;
    @apply rotate-6;
}

.ghost-card {
    @apply bg-gray-100/40 dark:bg-gray-600/40 !important;
}

/* calendar styles */

.fc .fc-toolbar {
    @apply flex-row-reverse justify-end px-4 !important;
}

.fc .fc-toolbar.fc-header-toolbar {
    @apply mb-5 !important;
}

.fc .fc-toolbar-title {
    @apply text-lg text-gray-900 font-semibold !important;
}

.fc .fc-today-button {
    @apply rounded-lg border border-gray-200 bg-white text-sm font-medium px-4 py-2 text-gray-900 hover:bg-gray-100 hover:text-primary-700 focus:z-10 focus:ring-2 focus:ring-primary-700 focus:text-primary-700 !important;
}

.fc-direction-ltr .fc-toolbar>*> :not(:first-child) {
    @apply mx-2 !important;
}

.fc .fc-button-group .fc-prev-button,
.fc .fc-button-group .fc-next-button {
    @apply bg-white border-0 text-gray-500 hover:text-gray-900 cursor-pointer p-2 hover:bg-gray-100 rounded inline-flex focus:bg-gray-100 focus:ring-1 focus:ring-gray-100 justify-center !important;
}

.fc .fc-scrollgrid {
    @apply border-l-0 border-gray-200 !important;
}

.fc .fc-daygrid-day-frame {
    @apply border-gray-200 !important;
}

.fc .fc-col-header-cell-cushion {
    @apply py-3 text-base text-gray-900 font-semibold !important;
}

.fc-theme-standard th {
    @apply border-0 border-b border-gray-200 !important;
}

.fc-direction-ltr .fc-daygrid-event.fc-event-end {
    @apply mr-2 !important;
}

.fc-direction-ltr .fc-daygrid-event.fc-event-start {
    @apply ml-2 !important;
}

.fc .fc-event .fc-event-main {
    @apply p-2 bg-primary-700 hover:bg-primary-800 !important;
}

.fc .fc-h-event .fc-event-main-frame {
    @apply text-xs font-semibold !important;
}

.fc .fc-daygrid-day-frame {
    @apply hover:bg-gray-50 cursor-pointer !important;
}

/* feed styles */

@media (min-width: 1280px) {
    .feed-container {
        height: calc(100vh - 4rem);
    }
}

@media (min-width: 640px) {
    .sm\:space-x-4> :not([hidden])~ :not([hidden]) {
        --tw-space-x-reverse: 1 !important;
    }
}

/*.btn-loading {*/
/*    position: relative;*/
/*}*/

.loading-spinner {
    display: none;

    /*position: relative;*/
    /*top: 50%;*/
    /*right: 0px; !* Adjust as needed *!*/
    /*transform: translateY(-50%);*/
}